<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <Logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="skin === 'dark'
              ? require('@/views/auth/forgotPassword/assets/illustration-dark.svg')
              : require('@/views/auth/forgotPassword/assets/illustration.svg')"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ $t('auth.forgotPassword.title') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('auth.forgotPassword.description') }}
          </b-card-text>

          <!-- form -->
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="onSubmit"
          >
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <b-form-input
                id="forgot-password-email"
                v-model="model.email"
                :state="errors.email ? false : null"
                name="forgot-password-email"
                placeholder="info@example.com"
              />
              <small
                v-if="errors.email"
                class="text-danger"
              >
                {{ errors.email }}
              </small>
            </b-form-group>

            <b-button
              class="mt-2"
              type="submit"
              variant="primary"
              block
            >
              {{ $t('auth.forgotPassword.submit') }}
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <b-link :to="{ name:'sign-in' }">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t('auth.forgotPassword.signIn') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { computed, ref } from '@vue/composition-api';
import Logo from '@core/layouts/components/Logo.vue';
import { useRouter } from '@core/utils/utils';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import { useValidation } from '@/hooks/useValidation';
import { emailValidator, fieldRequiredValidator } from '@/utils/validation';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    Logo,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/views/auth/forgotPassword/assets/illustration.svg'),
      required,
      email,
    };
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { router } = useRouter();
    const { welcomeToast, dangerToast } = useToast();

    const skin = computed(() => store.getters['appConfig/skin']);

    const model = ref({
      email: '',
    });

    const [validate, errors] = useValidation([
      fieldRequiredValidator(model.value, 'email', t('errors.message.emailRequired')),
      emailValidator(model.value, 'email', t('errors.message.emailInvalid')),
    ]);

    const isLoading = ref(false);

    const onSubmit = async () => {
      const isFormValid = validate();
      if (!isFormValid) return;

      isLoading.value = true;
      const { result } = await store.dispatch('user/restorePassword', {
        ...model.value,
      });
      isLoading.value = false;

      if (result) {
        welcomeToast(
          t('auth.forgotPassword.completeMessage.title'),
          t('auth.forgotPassword.completeMessage.text'),
        );
        router.push({ name: 'sign-in' });
        return;
      }

      dangerToast(
        t('auth.forgotPassword.error.title'),
        t('auth.forgotPassword.error.text'),
      );
    };

    return {
      t,

      skin,

      model,
      validate,
      errors,

      isLoading,
      onSubmit,

      required,
      email,
    };
  },

};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
